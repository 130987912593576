import {
  IFinishOrderReview,
  IOrderList,
  IOrderReviewItems,
} from '@/domain/models';
import makeOrderListPresenter from '@/main/factories/presenters/pages/order/order-list-presenter-factory';
import { CircularProgress, Modal, defaultInputStyles } from '@/ui/components';
import { ChevronRight } from '@mui/icons-material';
import { Button } from '@/components/atoms';

import { StoreState } from '@/ui/store/models';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import ImageCarousel from '../order-image-modal/caorulsel';
import { Column, Row } from '@/ui/components/layout';
import { useFieldArray, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { NotificationContext } from '@/main';
import { AuthenticationHelper } from '@/domain/helpers';
import { store } from '@/ui/store/config';
import { sidebarOpenAction } from '@/ui/store/modules';

import S from './styles';

interface IManualOrderReviewProps {
  rerenderTable: () => void;
}

export default function ManualOrderReview({
  rerenderTable,
}: IManualOrderReviewProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReviewingNextOrder, setIsReviewingNextOrder] = useState(false);
  const handleClose = () => {
    store.dispatch(
      sidebarOpenAction({
        sidebarName: '',
        sidebarState: { right: false },
        elementId: '',
        elementData: '',
      }),
    );
    setIsModalOpen(false);
  };
  const { successNotification, errorNotification, warningNotification } =
    useContext(NotificationContext);
  const orderListPresenter = makeOrderListPresenter();
  const modal = useSelector((store: StoreState) => store.sidebar);

  const { inputStyles } = defaultInputStyles();

  const { register, control, handleSubmit } = useForm<IFinishOrderReview>();
  const { fields, replace } = useFieldArray({
    name: 'items',
    control,
  });

  useEffect(() => {
    if (modal.sidebarName === 'manual_order_review' && modal.elementData) {
      setIsModalOpen(modal.sidebarState.right);
    }
  }, [modal]);

  const orderId = modal.elementId;

  const { data, isFetching: isLoading } = useQuery(
    ['order-review', orderId],
    () => orderListPresenter.getOrderReview(orderId as string),
    {
      enabled: isModalOpen && !!orderId,
      onSuccess: data => {
        if (!data || !data?.refrigeratorProducts) {
          return errorNotification('Erro ao carregar pedido');
        }

        const { refrigeratorProducts } = data;

        data?.refrigeratorProducts.forEach(item => {
          item.consumedQuantity = 0;
        });

        const productsMap = new Map<string, IOrderReviewItems>(
          refrigeratorProducts.map(item => [item.code, item]),
        );

        const items: IOrderReviewItems[] = Array.from(productsMap.values());

        replace(items);
      },
    },
  );

  const { mutateAsync, isLoading: isFinishOrderReviewLoading } = useMutation(
    (data: IFinishOrderReview) =>
      orderListPresenter.finishOrderReview(orderId as string, data),
    {
      onSuccess: res => {
        if (!res.success) {
          return errorNotification(res.message || 'Erro ao revisar pedido');
        }

        rerenderTable();
        successNotification(res.data.message || 'Pedido revisado com sucesso');

        const nextOrderToReview = data?.nextOrderToReview;

        if (isReviewingNextOrder && nextOrderToReview) {
          store.dispatch(
            sidebarOpenAction({
              sidebarName: 'manual_order_review',
              sidebarState: { right: true },
              elementId: nextOrderToReview,
              elementData: orderData,
            }),
          );

          setIsReviewingNextOrder(false);
        } else {
          handleClose();
        }
      },

      onError: e => {
        handleClose();
      },
    },
  );

  const submit = (data: IFinishOrderReview) => {
    if (data.items.find(item => item.consumedQuantity! < 0)) {
      return warningNotification(
        'Quantidade consumida não pode ser menor que zero',
      );
    }

    mutateAsync(data);
  };

  const orderData: IOrderList = modal.elementData;
  const location = orderData?.orderRefrigeratorResponse
    ? orderData.orderRefrigeratorResponse.pointOfSale
    : '';

  const getOrderType = {
    CONSUME: 'Pedido de Consumo',
    REPOSITION: 'Pedido de Reposição',
  };

  const licensedId = AuthenticationHelper.getToken()
    ? AuthenticationHelper.getToken().licensed_id
    : '';

  let canReview = true;

  if (
    isModalOpen &&
    orderData.replacementInform &&
    orderData.franchiseeId !== Number(licensedId)
  ) {
    canReview = false;
  }

  const modalTitleText = `#${orderId} - ${location} - ${
    getOrderType[orderData?.orderType as keyof typeof getOrderType]
  }`;

  const handleCoolerDetailsLink = () => {
    const coolerId = data?.coolerId;

    if (!coolerId) {
      return errorNotification('Cooler ID is missing.');
    }

    const coolerUrl = `/refrigerator/list/${coolerId}`;

    window.location.assign(coolerUrl);
  };

  const handleGoToNextOrder = async () => {
    setIsReviewingNextOrder(true);
    handleSubmit(submit)();
  };

  const isModalLoading = isLoading || isFinishOrderReviewLoading;

  return (
    <Modal
      maxWidth="90vw"
      isOpen={isModalOpen}
      onClose={handleClose}
      aria-labelledby="manual_order_review"
      aria-describedby="manual_order_review"
      data-testid="manual_order_review_modal"
      title={modalTitleText}
      okButton={
        <Button
          onClick={handleSubmit(handleGoToNextOrder)}
          color="secondary"
          disabled={isModalLoading}
        >
          <strong>Finalizar e ir para o próximo pedido</strong>
        </Button>
      }
      cancelButton={
        <Button
          disabled={isModalLoading}
          onClick={handleSubmit(submit)}
          type="submit"
        >
          <strong>Finalizar revisão</strong>
        </Button>
      }
    >
      {isModalLoading && (
        <Row width="80vw" align="center" justify="center" height="500px">
          <CircularProgress variant="indeterminate" />
        </Row>
      )}

      {data && !isModalLoading && (
        <Row width="80vw" overflow="auto" gap={4} justify="center">
          {data?.orderMediasResponse?.medias?.length > 0 ? (
            <S.CarouselWrapper>
              <S.CarouselToolbar>
                <Button
                  variant="text"
                  endIcon={<ChevronRight />}
                  onClick={handleCoolerDetailsLink}
                >
                  Ver detalhes do cooler
                </Button>
              </S.CarouselToolbar>
              <ImageCarousel grow={5} media={data.orderMediasResponse.medias} />
            </S.CarouselWrapper>
          ) : (
            <Column grow={5} align="center" justify="center">
              Nenhuma imagem encontrada.
            </Column>
          )}
          {canReview && (
            <S.FieldsFormWrapper
              gap={4}
              padding={{
                top: 4,
                bottom: 4,
              }}
              as="form"
              onSubmit={handleSubmit(submit)}
            >
              <S.FieldsContainer>
                {fields.map((field, index) => (
                  <S.InputWrapper key={index}>
                    <img src={field?.imageUrl} alt={field?.name} />
                    <TextField
                      sx={inputStyles}
                      key={field.id}
                      type="number"
                      {...register(`items.${index}.consumedQuantity`)}
                      label={field.name}
                    />
                  </S.InputWrapper>
                ))}
              </S.FieldsContainer>
            </S.FieldsFormWrapper>
          )}
        </Row>
      )}
    </Modal>
  );
}
